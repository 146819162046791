/**
 * action
 *
 * @version 0.1 (2021.06.16 : usayama)
 * @version 0.2 (2021.06.17 : usayama) // setPayment 追加
 */
const actions = {
  // getStripeSession
  INIT_GET_STRIPE_SESSION: 'INIT_GET_STRIPE_SESSION',
  GET_STRIPE_SESSION: 'GET_STRIPE_SESSION',
  GET_STRIPE_SESSION_RESULT: 'GET_STRIPE_SESSION_RESULT',
  initGetStripeSession: () => ({ 
    type: actions.INIT_GET_STRIPE_SESSION,
  }),
  getStripeSession: (formValues) => ({ 
    type: actions.GET_STRIPE_SESSION,
    payload: { formValues },
  }),
  INIT_SET_PAYMENT: 'INIT_SET_PAYMENT',
  SET_PAYMENT: 'SET_PAYMENT',
  SET_PAYMENT_RESULT: 'SET_PAYMENT_RESULT',
  initSetPayment: () => ({ 
    type: actions.INIT_SET_PAYMENT,
  }),
  setPayment: (formValues) => ({ 
    type: actions.SET_PAYMENT,
    payload: { formValues },
  }),
  INIT_IS_OPEN_PAYMENT: 'INIT_IS_OPEN_PAYMENT',
  initIsOpenPayment: () => ({ 
    type: actions.INIT_IS_OPEN_PAYMENT,
  }),
  OPEN_PAYMENT: 'OPEN_PAYMENT',
  openPayment: () => ({ 
    type: actions.OPEN_PAYMENT,
  }),
  CLOSE_PAYMENT: 'CLOSE_PAYMENT',
  closePayment: () => ({ 
    type: actions.CLOSE_PAYMENT,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.16 : usayama))
 */
export default actions;

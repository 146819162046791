/**
 * import
 *
 * @version 0.1 (2021.06.16 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetStripeSession
 *
 * @version 0.1 (2021.06.16 : usayama)
 */
const onGetStripeSession = async (values) => {
  return await axios.post(AJAX_ROUTE.getStripeSession, values, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getStripeSession() {
  yield takeEvery(actions.GET_STRIPE_SESSION, function*({payload}) {
    const getStripeSessionResult = yield call( onGetStripeSession, payload.formValues );
    yield put({
      type: actions.GET_STRIPE_SESSION_RESULT,
      getStripeSessionResult: getStripeSessionResult,
    });
  });
}

/**
 * SetPayment
 *
 * @version 0.1 (2021.06.17 : usayama)
 */
const onSetPayment = async (values) => {
  return await axios.post(AJAX_ROUTE.setPayment, values, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* setPayment() {
  yield takeEvery(actions.SET_PAYMENT, function*({payload}) {
    const setPaymentResult = yield call( onSetPayment, payload.formValues );
    yield put({
      type: actions.SET_PAYMENT_RESULT,
      setPaymentResult: setPaymentResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.16 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getStripeSession),
    fork(setPayment),
  ]);
}

/**
 * import
 *
 * @version 0.1 (2021.06.16 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.16 : usayama)
 * @version 0.2 (2021.06.17 : usayama) // setPayment 追加
 */
const initState = { 
  // view
  loadGetStripeSession: false,
  loadSetPayment: false,
  // result
  result: null,
  getStripeSessionResult: null,
  getStripeSessionResultStatus: null,
  setPaymentResult: null,
  setPaymentResultStatus: null,
  // data
  stripeSession: {},
  isOpenPayment: false,
  isPaid: false,
}

/**
 * export
 *
 * @version 0.1 (2021.06.16 : usayama)
 * @version 0.2 (2021.06.17 : usayama) // setPayment 追加
 */
export default function stripeSessionReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_STRIPE_SESSION --- */
    case actions.INIT_GET_STRIPE_SESSION: 
      return {
        ...state,
        loadGetStripeSession: false,
        result: null,
        stripeSession: {},
        getStripeSessionResult: null,
        getStripeSessionResultStatus: null,
      }
    case actions.GET_STRIPE_SESSION:
      return {
        ...state,
        loadGetStripeSession: true,
      }
    case actions.GET_STRIPE_SESSION_RESULT:
      return {
        ...state,
        loadGetStripeSession: false,
        result: action.getStripeSessionResult,
        stripeSession: action.getStripeSessionResult.stripe_session && action.getStripeSessionResult.stripe_session,
        getStripeSessionResult: action.getStripeSessionResult,
        getStripeSessionResultStatus: getResultStatusCode(action.getStripeSessionResult),
      }
     /* --- SET_PAYMENT --- */
    case actions.INIT_SET_PAYMENT: 
      return {
        ...state,
        loadSetPayment: false,
        result: null,
        setPaymentResult: null,
        setPaymentResultStatus: null,
        isPaid: false,
      }
    case actions.SET_PAYMENT:
      return {
        ...state,
        loadSetPayment: true,
      }
    case actions.SET_PAYMENT_RESULT:
      return {
        ...state,
        loadSetPayment: false,
        result: action.setPaymentResult,
        setPaymentResult: action.setPaymentResult,
        setPaymentResultStatus: getResultStatusCode(action.setPaymentResult),
        isPaid: action.setPaymentResult.is_paid,
      }
     /* --- INIT_IS_OPEN_PAYMENT --- */
    case actions.INIT_IS_OPEN_PAYMENT: 
      return {
        ...state,
        isOpenPayment: false,
      }
     /* --- OPEN_PAYMENT --- */
    case actions.OPEN_PAYMENT: 
      return {
        ...state,
        isOpenPayment: true,
      }
     /* --- CLOSE_PAYMENT --- */
    case actions.CLOSE_PAYMENT: 
      return {
        ...state,
        isOpenPayment: false,
      }
    default:
      return state
  }
}

/**
 * action
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const actions = {
  // getShops
  INIT_GET_SHOPS: 'INIT_GET_SHOPS',
  GET_SHOPS: 'GET_SHOPS',
  GET_SHOPS_RESULT: 'GET_SHOPS_RESULT',
  initGetShops: () => ({ 
    type: actions.INIT_GET_SHOPS,
  }),
  getShops: () => ({ 
    type: actions.GET_SHOPS,
  }),
  // getShop
  INIT_GET_SHOP: 'INIT_GET_SHOP',
  GET_SHOP: 'GET_SHOP',
  GET_SHOP_RESULT: 'GET_SHOP_RESULT',
  initGetShop: () => ({ 
    type: actions.INIT_GET_SHOP,
  }),
  getShop: (id) => ({ 
    type: actions.GET_SHOP,
    id: id,
  }),
  INIT_GET_SHOP_EMAIL_TEMPLATES: 'INIT_GET_SHOP_EMAIL_TEMPLATES',
  GET_SHOP_EMAIL_TEMPLATES: 'GET_SHOP_EMAIL_TEMPLATES',
  GET_SHOP_EMAIL_TEMPLATES_RESULT: 'GET_SHOP_EMAIL_TEMPLATES_RESULT',
  initGetShopEmailTemplates: () => ({ 
    type: actions.INIT_GET_SHOP_EMAIL_TEMPLATES,
  }),
  getShopEmailTemplates: (shops_id) => ({ 
    type: actions.GET_SHOP_EMAIL_TEMPLATES,
    shops_id: shops_id,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama))
 */
export default actions;

/**
 * action
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
const actions = {
  // getBrands
  INIT_GET_BRANDS: 'INIT_GET_BRANDS',
  GET_BRANDS: 'GET_BRANDS',
  GET_BRANDS_RESULT: 'GET_BRANDS_RESULT',
  initGetBrands: () => ({ 
    type: actions.INIT_GET_BRANDS,
  }),
  getBrands: () => ({ 
    type: actions.GET_BRANDS,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.29 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2020.07.17 : usayama)
 */
import actions from './actions';

/**
 * const
 *
 * @version 0.1 (2020.07.17 : usayama)
 */
const initState = { 
  // view
  loadingGetAddress: false,
  // data/
  address: '',
};

/**
 * export
 *
 * @version 0.1 (2020.07.17 : usayama)
 */
export default function toolsReducer(state = initState, action) {  
  switch (action.type) {
    /* --- GET_ADDRESS --- */
    case actions.GET_ADDRESS:
          return {
            ...state,
            loadingGetAddress: true,
            address: '',
          };
    case actions.GET_ADDRESS_RESULT:
          return {
            ...state,
            loadingGetAddress: false,
            address: (action.getAddressResult.data) ? action.getAddressResult.data : '',
          };
    default:
      return state;
  }
}
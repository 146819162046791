/**
 * action
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const actions = {
  // getOrderItems
  INIT_GET_ORDER_ITEMS: 'INIT_GET_ORDER_ITEMS',
  GET_ORDER_ITEMS: 'GET_ORDER_ITEMS',
  GET_ORDER_ITEMS_RESULT: 'GET_ORDER_ITEMS_RESULT',
  initGetOrderItems: () => ({ 
    type: actions.INIT_GET_ORDER_ITEMS,
  }),
  getOrderItems: () => ({ 
    type: actions.GET_ORDER_ITEMS,
  }),
  // getOrderItem
  INIT_GET_ORDER_ITEM: 'INIT_GET_ORDER_ITEM',
  GET_ORDER_ITEM: 'GET_ORDER_ITEM',
  GET_ORDER_ITEM_RESULT: 'GET_ORDER_ITEM_RESULT',
  initGetOrderItem: () => ({ 
    type: actions.INIT_GET_ORDER_ITEM,
  }),
  getOrderItem: (id) => ({ 
    type: actions.GET_ORDER_ITEM,
    id: id,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama))
 */
export default actions;

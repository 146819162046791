/**
 * import
 *
 * @version 0.1 (2020.05.13 : usayama_dx)
 * @version 0.2 (2020.05.13 : usayama_dx) // React 追加
 * @version 0.3 (2020.05.14 : usayama_dx) // i18n 追加
 * @version 0.4 (2021.05.17 : usayama_dx) // axios 追加 -> 削除
 * @version 0.5 (2021.05.18 : usayama_dx) // middleware 追加
 * @version 0.6 (2021.05.20 : usayama_dx) // axios, getCsrfToken 追加
 */
import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '@boot/root-reducer'
import rootSaga from '@boot/root-saga'
import '@config/i18n'
// config
import { AJAX_ROUTE } from '@config/routers'
// middleware
// import { logger } from './middleware'
// action
import axios from 'axios'
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.get(AJAX_ROUTE.getCsrfToken).then(res => res.data).catch(error => error.response && error.response.data)

/**
 * const
 *
 * @version 0.1 (2020.05.13 : usayama_dx)
 * @version 0.2 (2020.05.13 : usayama_dx) // sagaMiddleware を export へ移す
 * @version 0.3 (2020.05.18 : usayama_dx) // getCorsToken 追加
 * @version 0.4 (2020.05.18 : usayama_dx) // logger 追加
 */
const sagaMiddleware = createSagaMiddleware()
// const middlewares = [thunk, sagaMiddleware, logger]
const middlewares = [thunk, sagaMiddleware]
const bindMiddleware = middleware => {
  	if (process.env.NODE_ENV !== 'production') {
    	const { composeWithDevTools } = require('redux-devtools-extension')
    	return composeWithDevTools(applyMiddleware(...middleware))
  	}
  	return applyMiddleware(...middleware)
}

/**
 * export
 *
 * @version 0.1 (2020.05.13 : usayama_dx)
 * @version 0.2 (2020.05.13 : usayama_dx) // 以下参考に、記述内容変更
 * https://github.com/listenrightmeow/gatsby-redux-saga-poc/blob/master/src/store/index.js
 * https://techtechmedia.com/cannnot-export-arrow-function/
 */
const RootElementStore = ({ element }) => {
  	// const middleware = createSagaMiddleware()
  	const store = createStore(rootReducer, bindMiddleware(middlewares))
  	sagaMiddleware.run(rootSaga)
	return <Provider store={store}>{element}</Provider>
}

/**
 * export
 *
 * @version 0.1 (2020.05.13 : usayama_dx)
 */
export default RootElementStore
/**
 * action
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
const actions = {
  // getMe
  INIT_GET_ME: 'INIT_GET_ME',
  GET_ME: 'GET_ME',
  // GET_ME_RESULT: 'GET_ME_RESULT',
  initGetMe: () => ({ 
    type: actions.INIT_GET_ME,
  }),
  getMe: () => ({ 
    type: actions.GET_ME,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama))
 */
export default actions;

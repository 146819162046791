/**
 * action
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
const actions = {
  // getCartForm
  INIT_GET_CART_FORM: 'INIT_GET_CART_FORM',
  GET_CART_FORM: 'GET_CART_FORM',
  GET_CART_FORM_RESULT: 'GET_CART_FORM_RESULT',
  initGetCartForm: () => ({ 
    type: actions.INIT_GET_CART_FORM,
  }),
  getCartForm: () => ({ 
    type: actions.GET_CART_FORM,
  }),
  // setCartForm
  INIT_SET_CART_FORM: 'INIT_SET_CART_FORM',
  SET_CART_FORM: 'SET_CART_FORM',
  SET_CART_FORM_RESULT: 'SET_CART_FORM_RESULT',
  initSetCartForm: () => ({ 
    type: actions.INIT_SET_CART_FORM,
  }),
  setCartForm: (formValues) => ({ 
    type: actions.SET_CART_FORM,
    payload: { formValues },
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.15 : usayama))
 */
export default actions;

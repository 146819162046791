/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.06.19 : usayama) // helpers 追加
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
// import { ajaxGetRequestHeader, ajaxPostRequestHeader, ajaxCrosPostRequestHeader } from '@lib/helpers'
// import { showAlertMessage } from '@lib/appHelpers'
// const

/**
 * SignUp
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.19 : usayama) // error -> error.response.data
 */
const onSignUp = async (values) => {
  return await axios.post(AJAX_ROUTE.signUp, { 'data' : values }).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* signUp() {
  yield takeEvery(actions.SIGN_UP, function*({payload}) {
    const signUpResult = yield call( onSignUp, payload.values )
    yield put({
      type: actions.SIGN_UP_RESULT,
      signUpResult: signUpResult,
    })
  })
}

/**
 * RegisterAccount
 *
 * @version 0.1 (2021.05.21 : usayama)
 * @version 0.2 (2021.06.21 : usayama) // registerAccount に変更
 */
const onRegisterAccount = async (values) => {
  return await axios.post(AJAX_ROUTE.registerAccount, values ).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* registerAccount() {
  yield takeEvery(actions.REGISTER_ACCOUNT, function*({payload}) {
    const registerAccountResult = yield call( onRegisterAccount, payload.values )
    yield put({
      type: actions.REGISTER_ACCOUNT_RESULT,
      registerAccountResult: registerAccountResult,
    })
  })
}

/**
 * VerifyRegisterUrl
 *
 * @version 0.1 (2021.05.21 : usayama)
 */
const onVerifyRegisterUrl = async (values) => {
  return await axios.post(AJAX_ROUTE.verifyRegisterUrl, values).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* verifyRegisterUrl() {
  yield takeEvery(actions.VERIFY_REGISTER_URL, function*({payload}) {
    const verifyRegisterUrlResult = yield call( onVerifyRegisterUrl, payload.values )
    yield put({
      type: actions.VERIFY_REGISTER_URL_RESULT,
      verifyRegisterUrlResult: verifyRegisterUrlResult,
    })
  })
}

/**
 * GetCsrfToken
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.19 : usayama) // error -> error.response.data
 */
/*
const onGetCsrfToken = async () => {
  return await axios.get(AJAX_ROUTE.getCsrfToken).then(res => res.data).catch(error => error.response && error.response.data)
}
*/
export function* getCsrfToken() {
  yield takeEvery(actions.GET_CSRF_TOKEN, function*() {
    // const getCsrfTokenResult = yield call( onGetCsrfToken )
    yield put({
      type: actions.GET_CSRF_TOKEN_RESULT,
      // getCsrfTokenResult: getCsrfTokenResult,
    })
  })
}

/**
 * Login
 *
 * @version 0.1 (2021.06.19 : usayama)
 */
const onLogin = async (values) => {
  return await axios.post(AJAX_ROUTE.login, { 'data' : values }).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* login() {
  yield takeEvery(actions.LOGIN, function*({payload}) {
    const loginResult = yield call( onLogin, payload.values )
    yield put({
      type: actions.LOGIN_RESULT,
      loginResult: loginResult,
    })
  })
}

/**
 * GetMe
 *
 * @version 0.1 (2021.06.19 : usayama)
 */
const onGetMe = async () => {
  return await axios.get(AJAX_ROUTE.getMe).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getMe() {
  yield takeEvery(actions.GET_ME, function*() {
    const getMeResult = yield call( onGetMe )
    yield put({
      type: actions.GET_ME_RESULT,
      getMeResult: getMeResult,
    })
  })
}

/**
 * Logout
 *
 * @version 0.1 (2021.06.19 : usayama)
 */
const onLogout = async () => {
  return await axios.get(AJAX_ROUTE.logout).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    const logoutResult = yield call( onLogout )
    yield put({
      type: actions.LOGOUT_RESULT,
      logoutResult: logoutResult,
    })
  })
}

/**
 * ForgotPassword
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
const onForgotPassword = async (values) => {
  return await axios.post(AJAX_ROUTE.forgotPassword, values).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* forgotPassword() {
  yield takeEvery(actions.FORGOT_PASSWORD, function*({payload}) {
    const forgotPasswordResult = yield call( onForgotPassword, payload.values )
    yield put({
      type: actions.FORGOT_PASSWORD_RESULT,
      forgotPasswordResult: forgotPasswordResult,
    })
  })
}

/**
 * ChangePasswordAccount
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
const onChangePasswordAccount = async (values) => {
  return await axios.post(AJAX_ROUTE.changePassword, values).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* changePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD, function*({payload}) {
    const changePasswordResult = yield call( onChangePasswordAccount, payload.values )
    yield put({
      type: actions.CHANGE_PASSWORD_RESULT,
      changePasswordResult: changePasswordResult,
    })
  })
}

/**
 * VerifyChangePasswordUrl
 *
 * @version 0.1 (2021.06.25 : usayama)
 */
const onVerifyChangePasswordUrl = async (values) => {
  return await axios.post(AJAX_ROUTE.verifyChangePasswordUrl, values).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* verifyChangePasswordUrl() {
  yield takeEvery(actions.VERIFY_CHANGE_PASSWORD_URL, function*({payload}) {
    const verifyChangePasswordUrlResult = yield call( onVerifyChangePasswordUrl, payload.values )
    yield put({
      type: actions.VERIFY_CHANGE_PASSWORD_URL_RESULT,
      verifyChangePasswordUrlResult: verifyChangePasswordUrlResult,
    })
  })
}

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // GetCsrfToken 追加
 * @version 0.3 (2021.06.21 : usayama) // Login, getMe, logout,register, verifyRegisterUrl 追加
 * @version 0.4 (2021.06.21 : usayama) // registerAccount に変更
 * @version 0.5 (2021.06.25 : usayama) // パスワードリセット関連 追加
 */
export default function* rootSaga() {
  yield all([
    fork(signUp),
    fork(registerAccount),
    fork(verifyRegisterUrl),
    fork(getCsrfToken),
    fork(login),
    fork(getMe),
    fork(logout),
    fork(forgotPassword),
    fork(changePassword),
    fork(verifyChangePasswordUrl),
  ])
}

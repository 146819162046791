/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
import { all } from 'redux-saga/effects';
// import fetch from 'node-fetch';
// conf, action
// import { AJAX_ROUTE } from '@config/routers';
// import actions from './actions';
// components
// helper
// import { ajaxGetRequestHeader, ajaxPostRequestHeader } from '@lib/helpers';
// const

/**
 * GetMe
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
/*
const onGetRecommend = async (values) => {
  return await fetch(AJAX_ROUTE['getRecommend'], ajaxGetRequestHeader()).then(res => res.json()).then(res => res).catch(error => error);
}
export function* getMe() {
  yield takeEvery(actions.GET_RECOMMEND, function*() {
    const getRecommendResult = yield call( onGetRecommend );
    yield put({
      type: actions.GET_RECOMMEND_RESULT,
      getRecommendResult: getRecommendResult,
    });
  });
}
*/

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
export default function* rootSaga() {
  yield all([
//    fork(getMe),
  ]);
}

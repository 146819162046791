/**
 * const
 *
 * @version 0.1 (2020.07.16 : usayama)
 */
const actions = {
	GET_ADDRESS: 'GET_ADDRESS',
	GET_ADDRESS_RESULT: 'GET_ADDRESS_RESULT',
	getAddress: (values) => ({ 
		type: actions.GET_ADDRESS,
		payload: { values },
	}),
};

/**
 * export
 *
 * @version 0.1 (2020.07.16 : usayama)
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
import actions from './actions';
// helper

/**
 * initState
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
const initState = { 
  // view
  loadGetCartForm: false,
  loadSetCartForm: false,
  // result
  // getCartFormResult: null,
  // data
  cartForm: {
    'name': '',
    'zip_code': '',
    'address_state': '',
    'address_city': '',
    'address_street': '',
    'tel': '',
    'email': '',
  },
};

/**
 * export
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export default function cartFormReducer(state = initState, action) {
  // action
  switch (action.type) {
     /* --- GET_CART_FORM --- */
    case actions.INIT_GET_CART_FORM: 
      return {
        ...state,
        loadGetCartForm: false,
        cartForm: {},
      };
    case actions.GET_CART_FORM:
      return {
        ...state,
        loadGetCartForm: false,
        cartForm: state.cartForm,
      };
    /*
    case actions.GET_CART_FORM_RESULT:
      return {
        ...state,
        loadGetCartForm: false,
        cartForm: action.cartForm,
      };
    */
    /* --- SET_CART_FORM --- */
    case actions.INIT_SET_CART_FORM: 
      return {
        ...state,
        loadSetCartForm: false,
      };
    case actions.SET_CART_FORM:
      return {
        ...state,
        loadSetCartForm: false,
        cartForm: action.payload.formValues,
      };
    /*
    case actions.SET_CART_FORM_RESULT:
      return {
        ...state,
        loadSetCartForm: false,
        cartForm: action.payload.formValues,
      };
    */
    default:
      return state;
  }
}

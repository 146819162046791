/**
 * import
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
import { all } from 'redux-saga/effects';
// import fetch from 'node-fetch';
// conf, action
// import { AJAX_ROUTE } from '@config/routers';
// import actions from './actions';
// components
// helper
// import { getLocalStorage, setLocalStorage, isEmpty } from '@lib/helpers';
// const


/**
 * export
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export default function* rootSaga() {
  yield all([
    // fork(setCartForm),
  ]);
}

/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const initState = { 
  // view
  loadGetOrders: false,
  loadGetOrder: false,
  // result
  result: null,
  getOrdersResultStatus: null,
  getOrderResultStatus: null,
  // data
  orders: [],
  order: {},
}

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
export default function orderReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_ORDERS --- */
    case actions.INIT_GET_ORDERS: 
      return {
        ...state,
        loadGetOrders: false,
        result: null,
        getOrdersResultStatus: null,
        orders: [],
      }
    case actions.GET_ORDERS:
      return {
        ...state,
        loadGetOrders: true,
      }
    case actions.GET_ORDERS_RESULT:
      return {
        ...state,
        loadGetOrders: false,
        result: action.getOrdersResult,
        getOrdersResultStatus: getResultStatusCode(action.getOrdersResult),
        orders: action.getOrdersResult.items,
      }
     /* --- GET_ORDER --- */
    case actions.INIT_GET_ORDER: 
      return {
        ...state,
        loadGetOrder: false,
        result: null,
        getOrderResultStatus: null,
      }
    case actions.GET_ORDER:
      return {
        ...state,
        loadGetOrder: true,
      }
    case actions.GET_ORDER_RESULT:
      return {
        ...state,
        loadGetOrder: false,
        result: action.getOrderResult,
        getOrderResultStatus: getResultStatusCode(action.getOrderResult),
        order: action.getOrderResult.item,
      }
    default:
      return state
  }
}

/**
 * import
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
const initState = { 
  // view
  loadContact: false,
  // result
  result: null,
  contactResult: null,
  contactResultStatus: null,
  // data
}

/**
 * export
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
export default function authReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- CONTACT --- */
    case actions.INIT_CONTACT: 
      return {
        ...state,
        loadContact: false,
        contactResult: null,
        contactResultStatus: null,
      }
    case actions.CONTACT:
      return {
        ...state,
        loadContact: true,
      }
    case actions.CONTACT_RESULT:
      return {
        ...state,
        loadContact: false,
        contactResult: action.contactResult,
        contactResultStatus: getResultStatusCode(action.contactResult),
      }
    default:
      return state
  }
}

/**
 * import
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetItems
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
const onGetItems = async () => {
  return await axios.post(AJAX_ROUTE.getItems, {}, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getItems() {
  yield takeEvery(actions.GET_ITEMS, function*() {
    const getItemsResult = yield call( onGetItems );
    yield put({
      type: actions.GET_ITEMS_RESULT,
      getItemsResult: getItemsResult,
    });
  });
}

/**
 * GetItem
 *
 * @version 0.1 (2021.06.12 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // shop_url, item_url 追加
 */
const onGetItem = async (shop_url, item_url) => {
  return await axios.get(AJAX_ROUTE.getItem + '/' + shop_url + '/' + item_url, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getItem() {
  yield takeEvery(actions.GET_ITEM, function*({shop_url, item_url}) {
    const getItemResult = yield call( onGetItem, shop_url, item_url );
    yield put({
      type: actions.GET_ITEM_RESULT,
      getItemResult: getItemResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getItems),
    fork(getItem),
  ]);
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama_dx)
*/
import RootElementStore from '@src/boot/store'

/** 
 * export : 
 * 
 * @version 0.1 (2021.05.13 : usayama_dx)
*/
export const wrapRootElement = RootElementStore

/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const initState = { 
  // view
  loadGetInformations: false,
  loadGetInformation: false,
  // result
  result: null,
  getInformationsResult: null,
  getInformationResult: null,
  getInformationsResultStatus: null,
  getInformationResultStatus: null,
  // data
  informations: [],
  information: {},
}

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
export default function informationReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_INFORMATIONS --- */
    case actions.INIT_GET_INFORMATIONS: 
      return {
        ...state,
        loadGetInformations: false,
        result: null,
        getInformationsResult: null,
        getInformationsResultStatus: null,
        informations: [],
      }
    case actions.GET_INFORMATIONS:
      return {
        ...state,
        loadGetInformations: true,
      }
    case actions.GET_INFORMATIONS_RESULT:
      return {
        ...state,
        loadGetInformations: false,
        result: action.getInformationsResult,
        getInformationsResult: action.getInformationsResult,
        getInformationsResultStatus: getResultStatusCode(action.getInformationsResult),
        informations: action.getInformationsResult.items,
      }
     /* --- GET_INFORMATION --- */
    case actions.INIT_GET_INFORMATION: 
      return {
        ...state,
        loadGetInformation: false,
        result: null,
        getInformationResult: null,
        getInformationResultStatus: null,
        information: {},
      }
    case actions.GET_INFORMATION:
      return {
        ...state,
        loadGetInformation: true,
      }
    case actions.GET_INFORMATION_RESULT:
      // information
      let information = action.getInformationResult.item
      // set
      information.files = information.files && JSON.parse(information.files)
      information.shop_name = information.shop && information.shop.name
      return {
        ...state,
        loadGetInformation: false,
        result: action.getInformationResult,
        getInformationResult: action.getInformationResult,
        getInformationResultStatus: getResultStatusCode(action.getInformationResult),
        information: information,
      }
    default:
      return state
  }
}

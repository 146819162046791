/**
 * import
 *
 * @version 0.1 (2021.04.20 : usayama)
 */
// import { lazy } from 'react'

/**
 * route
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // まとめて 追加
 * @version 0.3 (2021.06.15 : usayama) // cart_form, cart_confirm, cart_thanks 追加
 * @version 0.4 (2021.06.25 : usayama) // forgot_password 追加
 * @version 0.5 (2021.09.06 : usayama) // remove 追加
 * @version 0.6 (2021.09.30 : usayama) // リンク追加
 * @version 0.7 (2023.05.07 : usayama) // リンク先変更
 * @version 0.8 (2024.08.29 : usayama) // リンク先追加
 */
export const APP_ROUTE = {
	'root' : '/',
	'about' : '/about',
	'cart' : '/cart',
	'cart_form' : '/cart/form',
	'cart_confirm' : '/cart/confirm',
	'cart_thanks' : '/cart/thanks',
	'contact' : '/contact',
	'faq' : '/faq',
	'index' : '/index',
	'legal' : '/legal',
	'like' : '/like',
	'informations' : '/news',
	'information' : '/news/',
	'privacy' : '/privacy',
	'terms' : '/terms',
	'shops' : '/shops',
	'shop' : '/shops/',
	'items' : '/items',
	'item' : '/items/',
	'mypage' : '/mypage',
	'order_items' : '/mypage/order_items',
	'points' : '/mypage/points',
	'payments' : '/mypage/payments',
	'login' : '/login',
	'signup' : '/signup',
	'forgot_password' : '/forgot_password',
	'change_password' : '/change_password',
	'logout' : '/logout',
  'remove' : '/remove',
  'event' : '/brand/category/event/',
  'zero_carbon_top' : '/brand/category/topics/',
  'brand' : '/brand/category/challengers/',
  'd_sharing' : '/brand/category/d-sharing-総研から/',
  'menu01' : '/brand/archives/category/脱炭素農産物',
  'menu02' : '/brand/archives/category/脱炭素商品',
  'menu03' : '/brand/archives/category/脱炭素生産・販売者',
  'menu04' : '/brand/archives/category/脱炭素プロジェクト',
}

/**
 * route
 *
 * @version 0.1 (2021.04.26 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // signup -> signUp
 * @version 0.3 (2021.05.17 : usayama) // process.env 追加
 * @version 0.4 (2021.05.18 : usayama) // userCheckAccount 追加
 * @version 0.5 (2021.05.20 : usayama) // getTerms 追加
 * @version 0.6 (2021.06.14 : usayama) // まとめて 追加 & 不要 route 削除
 * @version 0.7 (2021.06.15 : usayama) // getAddress 追加
 * @version 0.8 (2021.06.16 : usayama) // getStripeSession 追加
 * @version 0.9 (2021.06.16 : usayama) // setPayment 追加
 * @version 0.10 (2021.06.21 : usayama) // register, verifyRegisterUrl, getPoints, getOrderItems 追加
 * @version 0.11 (2021.06.25 : usayama) // パスワードリセット関連追加
 * @version 0.12 (2021.06.28 : usayama) // お問い合わせ追加
 * @version 0.13 (2021.06.29 : usayama) // getBrands 追加
 * @version 0.14 (2021.07.05 : usayama) // getTopics 追加
 * @version 0.15 (2021.07.09 : usayama) // getCategoryPosts 追加
 * @version 0.16 (2021.09.06 : usayama) // remove 追加
 */
export const AJAX_ROUTE = {
	'getMe' : process.env.API_URL + '/auth/getMe',
	'getCsrfToken' : process.env.API_URL + '/sanctum/csrf-cookie',
	'userCheckAccount' : process.env.API_URL + '/user/checkAccount',
	'signUp' : process.env.API_URL + '/auth/signUp',
	'registerAccount' : process.env.API_URL + '/auth/registerAccount',
	'verifyRegisterUrl' : process.env.API_URL + '/auth/verifyRegisterUrl',
	'forgotPassword' : process.env.API_URL + '/auth/forgotPassword',
  'changePassword' : process.env.API_URL + '/auth/changePassword',
  'verifyChangePasswordUrl' : process.env.API_URL + '/auth/verifyChangePasswordUrl',
	'login' : process.env.API_URL + '/auth/login',
	'logout' : process.env.API_URL + '/auth/logout',
  'remove' : process.env.API_URL + '/auth/remove',
	'getTerms' : process.env.API_URL + '/terms/get',
  'getShops' : process.env.API_URL + '/shop/gets',
  'getShop' : process.env.API_URL + '/shop/get',
  'getShopEmailTemplates' : process.env.API_URL + '/shop/getShopEmailTemplates',
  'setShopEmailTemplate' : process.env.API_URL + '/shop/setShopEmailTemplate',
	'getOrders' : process.env.API_URL + '/order/gets',
  'getOrder' : process.env.API_URL + '/order/get',
  'setOrder' : process.env.API_URL + '/order/set',
  'getItems' : process.env.API_URL + '/item/gets',
  'getItem' : process.env.API_URL + '/item/get',
  'getInformations' : process.env.API_URL + '/information/gets',
  'getInformation' : process.env.API_URL + '/information/get',
  'getStripeSession' : process.env.API_URL + '/payment/getStripeSession',
  'setPayment' : process.env.API_URL + '/payment/setPayment',
  "getAddress" : 'https://api.zipaddress.net/?zipcode=',
  'getPoints' : process.env.API_URL + '/point/gets',
  'getPoint' : process.env.API_URL + '/point/get',
  'getOrderItems' : process.env.API_URL + '/order_item/gets',
  'getOrderItem' : process.env.API_URL + '/order_item/get',
  'getBrands' : process.env.API_URL + '/brand/gets',
  'getTopics' : process.env.API_URL + '/wp/getTopics',
  'getCategoryPosts' : process.env.API_URL + '/wp/getCategoryPosts',
  'contact' : process.env.API_URL + '/support/contact',
}


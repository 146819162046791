/** 
 * import
 * 
 * @version 0.1 (2021.05.14 : usayama)
*/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationJa from '@locales/ja/ja_JP';

/** 
 * const
 * 
 * @version 0.1 (2021.05.14 : usayama)
*/
const resources = {
  ja: {
    translation: translationJa
  }
};

/** 
 * init
 * 
 * @version 0.1 (2021.05.14 : usayama)
*/
i18n.use(initReactI18next).init({
  resources,
  preload: ["ja"],
  lng: "ja",
  fallbackLng: 'ja',
  debug: false,
//  keySeparator: false,
//  nsSeparator: false,
//  keySeparator: '>',
//  nsSeparator: '|',
  interpolation: {
    escapeValue: false
  }
});

/** 
 * export
 * 
 * @version 0.1 (2021.05.14 : usayama)
*/
export default i18n;

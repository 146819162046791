/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetPoints
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const onGetPoints = async () => {
  return await axios.post(AJAX_ROUTE.getPoints, {}, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getPoints() {
  yield takeEvery(actions.GET_POINTS, function*() {
    const getPointsResult = yield call( onGetPoints );
    yield put({
      type: actions.GET_POINTS_RESULT,
      getPointsResult: getPointsResult,
    });
  });
}

/**
 * GetPoint
 *
 * @version 0.1 (2021.05.28 : usayama)
 */
const onGetPoint = async (id) => {
  return await axios.get(AJAX_ROUTE.getPoint + '/' + id, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getPoint() {
  yield takeEvery(actions.GET_POINT, function*({id}) {
    const getPointResult = yield call( onGetPoint, id );
    yield put({
      type: actions.GET_POINT_RESULT,
      getPointResult: getPointResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getPoints),
    fork(getPoint),
  ]);
}

/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const initState = { 
  // view
  loadGetShops: false,
  loadGetShop: false,
  loadGetShopEmailTemplates: false,
  // result
  result: null,
  getShopsResult: null,
  getShopResult: null,
  getShopEmailTemplatesResult: null,
  getShopsResultStatus: null,
  getShopResultStatus: null,
  getShopEmailTemplatesResultStatus: null,
  // data
  shops: [],
  shop: {},
}

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 * @version 0.2 (2021.08.13 : usayama) // profile_fields null 時の処理追加, item_profile_fields に修正
 */
export default function shopReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_SHOPS --- */
    case actions.INIT_GET_SHOPS: 
      return {
        ...state,
        loadGetShops: false,
        result: null,
        getShopsResult: null,
        getShopsResultStatus: null,
        shops: [],
      }
    case actions.GET_SHOPS:
      return {
        ...state,
        loadGetShops: true,
      }
    case actions.GET_SHOPS_RESULT:
      // shop
      let shops = action.getShopsResult.items
      // profile_fields
      shops.forEach((shop, i) => {
        const profile_fields = shop && shop.profile_fields && JSON.parse(shop.profile_fields)
        if(profile_fields) {
          shops[i].main_image = profile_fields.main_image && profile_fields.main_image
          shops[i].face_image = profile_fields.face_image && profile_fields.face_image
          shops[i].address = profile_fields.address && profile_fields.address
          shops[i].sections = profile_fields.sections && profile_fields.sections
        }
      })
      return {
        ...state,
        loadGetShops: false,
        result: action.getShopsResult,
        getShopsResult: action.getShopsResult,
        getShopsResultStatus: getResultStatusCode(action.getShopsResult),
        shops: action.getShopsResult.items,
      }
     /* --- GET_SHOP --- */
    case actions.INIT_GET_SHOP: 
      return {
        ...state,
        loadGetShop: false,
        result: null,
        getShopResult: null,
        getShopResultStatus: null,
        shop: {},
      }
    case actions.GET_SHOP:
      return {
        ...state,
        loadGetShop: true,
      }
    case actions.GET_SHOP_RESULT:
      // item
      let shop = action.getShopResult.item
      // profile_fields
      const profile_fields = shop && shop.profile_fields && JSON.parse(shop.profile_fields)
      if(profile_fields) {
        shop.main_image = profile_fields.main_image ? profile_fields.main_image : ''
        shop.face_image = profile_fields.face_image ? profile_fields.face_image : ''
        shop.address = profile_fields.address ? profile_fields.address : ''
        shop.sections = profile_fields.sections ? profile_fields.sections : []
      }
      shop.items.forEach((item, i) => {
        const item_profile_fields = shop.items[i] && shop.items[i].profile_fields && JSON.parse(shop.items[i].profile_fields)
        shop.items[i].table_keys = item_profile_fields && JSON.parse(item_profile_fields.table_keys)
        shop.items[i].table_values = item_profile_fields && JSON.parse(item_profile_fields.table_values)
        shop.items[i].item_images = (item_profile_fields && item_profile_fields.item_images) ? item_profile_fields.item_images : []
      })
      return {
        ...state,
        loadGetShop: false,
        result: action.getShopResult,
        getShopResult: action.getShopResult,
        getShopResultStatus: getResultStatusCode(action.getShopResult),
        shop: shop,
      }
     /* --- GET_SHOP_EMAIL_TEMPLATES --- */
    case actions.INIT_GET_SHOP_EMAIL_TEMPLATES: 
      return {
        ...state,
        loadGetShopEmailTemplates: false,
        result: null,
        getShopEmailTemplatesResult: null,
        getShopEmailTemplatesResultStatus: null,
        shop_email_templates: [],
      }
    case actions.GET_SHOP_EMAIL_TEMPLATES:
      return {
        ...state,
        loadGetShopEmailTemplates: true,
      }
    case actions.GET_SHOP_EMAIL_TEMPLATES_RESULT:
      return {
        ...state,
        loadGetShopEmailTemplates: false,
        result: action.getShopEmailTemplatesResult,
        getShopEmailTemplatesResult: action.getShopEmailTemplatesResult,
        getShopEmailTemplatesResultStatus: getResultStatusCode(action.getShopEmailTemplatesResult),
        shop_email_templates: action.getShopEmailTemplatesResult.items,
      }
    default:
      return state
  }
}

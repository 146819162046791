/**
 * import
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
import { all, takeEvery, put, fork } from 'redux-saga/effects';
// import fetch from 'node-fetch';
// conf, action
// import { AJAX_ROUTE } from '@config/routers';
import actions from './actions';
// components
// helper
import { getLocalStorage, setLocalStorage, removeLocalStorage, isEmpty } from '@lib/helpers';
// const

/**
 * getCartItems
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export function* getCartItems() {
  yield takeEvery(actions.GET_CART_ITEMS, function*() {
    yield put({
      type: actions.GET_CART_ITEMS_RESULT,
      getCartItemsResult: getLocalStorage('cartItems'),
    });
  });
}

/**
 * setCartItem
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export function* setCartItem() {
  yield takeEvery(actions.SET_CART_ITEM, function*({item}) {
    const cartItem = item.item
    let cartItems = getLocalStorage('cartItems')
    cartItems = isEmpty(cartItems) ? {} : cartItems
    cartItems = {...cartItems, [cartItem.id] : cartItem}
    setLocalStorage('cartItems', cartItems)
    yield put({
      type: actions.SET_CART_ITEM_RESULT,
      setCartItemsResult: getLocalStorage('cartItems'),
    });
  });
}

/**
 * removeCartItem
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export function* removeCartItem() {
  yield takeEvery(actions.REMOVE_CART_ITEM, function*({item}) {
    const removeCartItem = item.item
    let cartItems = getLocalStorage('cartItems')
    delete cartItems[removeCartItem.id];
    setLocalStorage('cartItems', cartItems)
    yield put({
      type: actions.REMOVE_CART_ITEM_RESULT,
      removeCartItemResult: getLocalStorage('cartItems'),
    });
  });
}

/**
 * removeCartItems
 *
 * @version 0.1 (2021.06.15 : usayama)
 */
export function* removeCartItems() {
  yield takeEvery(actions.REMOVE_CART_ITEMS, function*() {
    removeLocalStorage('cartItems')
    yield put({
      type: actions.REMOVE_CART_ITEMS_RESULT,
      removeCartItemsResult: {},
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.15 : usayama)
 * @version 0.2 (2021.06.17 : usayama) // removeCartItems 追加
 */
export default function* rootSaga() {
  yield all([
    fork(getCartItems),
    fork(setCartItem),
    fork(removeCartItem),
    fork(removeCartItems),
  ]);
}

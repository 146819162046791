/**
 * import
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
import actions from './actions';
// helper
import { getResultStatusCode } from '@lib/helpers';

/**
 * initState
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
const initState = { 
  // view
  loadGetBrands: false,
  // result
  result: null,
  getBrandsResult: null,
  getBrandsResultStatus: null,
  // data
  brands: [],
};

/**
 * export
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
export default function brandReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_BRANDS --- */
    case actions.INIT_GET_BRANDS: 
      return {
        ...state,
        loadGetBrands: false,
        result: null,
        getBrandsResult: null,
        getBrandsResultStatus: null,
        brands: [],
      };
    case actions.GET_BRANDS:
      return {
        ...state,
        loadGetBrands: true,
      };
    case actions.GET_BRANDS_RESULT:
      return {
        ...state,
        loadGetBrands: false,
        result: action.getBrandsResult,
        getBrandsResult: action.getBrandsResult,
        loginResultStatus: getResultStatusCode(action.getBrandsResult),
        brands: action.getBrandsResult.items,
      };
    default:
      return state;
  }
}

/**
 * action
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // GetCsrfToken 追加
 * @version 0.3 (2021.06.21 : usayama) // Login, getMe, logout, register 追加
 * @version 0.4 (2021.06.25 : usayama) // パスワードリセット関連 追加
 */
const actions = {
  // signUp
  INIT_SIGN_UP: 'INIT_SIGN_UP',
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_RESULT: 'SIGN_UP_RESULT',
  initSignUp: () => ({ 
    type: actions.INIT_SIGN_UP,
  }),
  signUp: (values) => ({ 
    type: actions.SIGN_UP,
    payload: { values },
  }),
  // registerAccount
  INIT_REGISTER_ACCOUNT: 'INIT_REGISTER_ACCOUNT',
  REGISTER_ACCOUNT: 'REGISTER_ACCOUNT',
  REGISTER_ACCOUNT_RESULT: 'REGISTER_ACCOUNT_RESULT',
  initRegisterAccount: () => ({ 
    type: actions.INIT_REGISTER_ACCOUNT,
  }),
  registerAccount: (values) => ({ 
    type: actions.REGISTER_ACCOUNT,
    payload: { values },
  }),
  INIT_VERIFY_REGISTER_URL: 'INIT_VERIFY_REGISTER_URL',
  VERIFY_REGISTER_URL: 'VERIFY_REGISTER_URL',
  VERIFY_REGISTER_URL_RESULT: 'VERIFY_REGISTER_URL_RESULT',
  initVerifyRegisterUrl: () => ({ 
    type: actions.INIT_VERIFY_REGISTER_URL,
  }),
  verifyRegisterUrl: (values) => ({ 
    type: actions.VERIFY_REGISTER_URL,
    payload: { values },
  }),
  // getCsrfToken
  INIT_GET_CSRF_TOKEN: 'INIT_GET_CSRF_TOKEN',
  GET_CSRF_TOKEN: 'GET_CSRF_TOKEN',
  GET_CSRF_TOKEN_RESULT: 'GET_CSRF_TOKEN_RESULT',
  initGetCsrfToken: () => ({ 
    type: actions.INIT_GET_CSRF_TOKEN,
  }),
  getCsrfToken: () => ({ 
    type: actions.GET_CSRF_TOKEN,
  }),
  // login
  INIT_LOGIN: 'INIT_LOGIN',
  LOGIN: 'LOGIN',
  LOGIN_RESULT: 'LOGIN_RESULT',
  initLogin: () => ({ 
    type: actions.INIT_LOGIN,
  }),
  login: (values) => ({ 
    type: actions.LOGIN,
    payload: { values },
  }),
  INIT_GET_ME: 'INIT_GET_ME',
  GET_ME: 'GET_ME',
  GET_ME_RESULT: 'GET_ME_RESULT',
  initGetMe: () => ({ 
    type: actions.INIT_GET_ME,
  }),
  getMe: () => ({ 
    type: actions.GET_ME,
  }),
  // logout
  LOGOUT: 'LOGOUT',
  LOGOUT_RESULT: 'LOGOUT_RESULT',
  logout: () => ({ 
    type: actions.LOGOUT,
  }),
  // forgotPassword
  INIT_FORGOT_PASSWORD: 'INIT_FORGOT_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_RESULT: 'FORGOT_PASSWORD_RESULT',
  initForgotPassword: () => ({ 
    type: actions.INIT_FORGOT_PASSWORD,
  }),
  forgotPassword: (values) => ({ 
    type: actions.FORGOT_PASSWORD,
    payload: { values },
  }),
  // changePassword
  INIT_CHANGE_PASSWORD: 'INIT_CHANGE_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_RESULT: 'CHANGE_PASSWORD_RESULT',
  initChangePassword: () => ({ 
    type: actions.INIT_CHANGE_PASSWORD,
  }),
  changePassword: (values) => ({ 
    type: actions.CHANGE_PASSWORD,
    payload: { values },
  }),
  INIT_VERIFY_CHANGE_PASSWORD_URL: 'INIT_VERIFY_CHANGE_PASSWORD_URL',
  VERIFY_CHANGE_PASSWORD_URL: 'VERIFY_CHANGE_PASSWORD_URL',
  VERIFY_CHANGE_PASSWORD_URL_RESULT: 'VERIFY_CHANGE_PASSWORD_URL_RESULT',
  initVerifyChangePasswordUrl: () => ({ 
    type: actions.INIT_VERIFY_CHANGE_PASSWORD_URL,
  }),
  verifyChangePasswordUrl: (values) => ({ 
    type: actions.VERIFY_CHANGE_PASSWORD_URL,
    payload: { values },
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama))
 */
export default actions;

/**
 * action
 *
 * @version 0.1 (2021.01.15 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // data 追加
 * @version 0.3 (2021.05.20 : usayama) // showModal -> openModal に変更
 */
const actions = {
  INIT_MODAL: 'INIT_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  initModal: () => ({ 
    type: actions.INIT_MODAL,
  }),
  openModal: (data) => ({ 
    type: actions.OPEN_MODAL,
    data: { data },
  }),
  closeModal: () => ({ 
    type: actions.CLOSE_MODAL,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.01.15 : usayama)
 */
export default actions;

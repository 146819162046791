/**
 * action
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const actions = {
  // getPoints
  INIT_GET_POINTS: 'INIT_GET_POINTS',
  GET_POINTS: 'GET_POINTS',
  GET_POINTS_RESULT: 'GET_POINTS_RESULT',
  initGetPoints: () => ({ 
    type: actions.INIT_GET_POINTS,
  }),
  getPoints: () => ({ 
    type: actions.GET_POINTS,
  }),
  // getPoint
  INIT_GET_POINT: 'INIT_GET_POINT',
  GET_POINT: 'GET_POINT',
  GET_POINT_RESULT: 'GET_POINT_RESULT',
  initGetPoint: () => ({ 
    type: actions.INIT_GET_POINT,
  }),
  getPoint: (id) => ({ 
    type: actions.GET_POINT,
    id: id,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2021.05.13 : usayama)
 */
import actions from './actions';
// helper

/**
 * initState
 *
 * @version 0.1 (2021.05.13 : usayama)
 */
const initState = { 
  // view
  loadGetCartItems: false,
  loadSetCartItem: false,
  // result
  // getCartItemsResult: null,
  // data
  cartItems: {},
};

/**
 * export
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.05.13 : usayama) // add, remove 追加
 * @version 0.3 (2021.06.14 : usayama) // addCartItem 実装
 */
export default function cartReducer(state = initState, action) {
  // action
  switch (action.type) {
     /* --- GET_CART_ITEMS --- */
    case actions.INIT_GET_CART_ITEMS: 
      return {
        ...state,
        loadGetCartItems: false,
        cartItems: [],
      };
    case actions.GET_CART_ITEMS:
      return {
        ...state,
        loadGetCartItems: true,
      };
    case actions.GET_CART_ITEMS_RESULT:
      return {
        ...state,
        loadGetCartItems: false,
        cartItems: action.getCartItemsResult,
      };
    /* --- SET_CART_ITEM --- */
    case actions.INIT_SET_CART_ITEM: 
      return {
        ...state,
        loadSetCartItem: false,
      };
    case actions.SET_CART_ITEM:
      return {
        ...state,
        loadSetCartItem: true,
      };
    case actions.SET_CART_ITEM_RESULT:
      return {
        ...state,
        loadSetCartItem: false,
        cartItems: action.setCartItemsResult,
      };
    /* --- REMOVE_CART_ITEM --- */
    case actions.INIT_REMOVE_CART_ITEM: 
      return {
        ...state,
        loadRemoveCartItem: false,
      };
    case actions.REMOVE_CART_ITEM:
      // cartItems.splice(1,1)
      return {
        ...state,
        loadRemoveCartItem: true,
        // cartItems: cartItems,
      };
    case actions.REMOVE_CART_ITEM_RESULT:
      return {
        ...state,
        loadRemoveCartItem: false,
        cartItems: action.removeCartItemResult,
      };
    /* --- REMOVE_CART_ITEMS --- */
    case actions.REMOVE_CART_ITEMS:
      // cartItems.splice(1,1)
      return {
        ...state,
        loadRemoveCartItems: true,
        // cartItems: cartItems,
      };
    case actions.REMOVE_CART_ITEMS_RESULT:
      return {
        ...state,
        loadRemoveCartItems: false,
        cartItems: action.removeCartItemsResult,
      };
    default:
      return state;
  }
}

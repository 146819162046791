/**
 * import
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // account, auth, modal 追加
 * @version 0.3 (2021.05.18 : usayama) // account -> user
 * @version 0.4 (2021.05.20 : usayama) // terms 追加
 * @version 0.5 (2021.06.12 : usayama) // 項目 追加
 * @version 0.6 (2021.06.12 : usayama) // cartForm, tools 追加
 * @version 0.7 (2021.06.16 : usayama) // payment 追加
 * @version 0.8 (2021.06.21 : usayama) // point, orderItem 追加
 * @version 0.9 (2021.06.28 : usayama) // support 追加
 * @version 0.10 (2021.06.29 : usayama) // brand 追加
 */
import { all } from 'redux-saga/effects'
import cart from '@redux/cart/saga'
import user from '@redux/user/saga'
import auth from '@redux/auth/saga'
import modal from '@redux/modal/saga'
import terms from '@redux/terms/saga'
import shop from '@redux/shop/saga'
import order from '@redux/order/saga'
import item from '@redux/item/saga'
import information from '@redux/information/saga'
import cartForm from '@redux/cartForm/saga'
import tools from '@redux/tools/saga'
import payment from '@redux/payment/saga'
import point from '@redux/point/saga'
import order_item from '@redux/order_item/saga'
import support from '@redux/support/saga'
import brand from '@redux/brand/saga'

/**
 * export
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // user, auth, modal 追加
 * @version 0.3 (2021.05.18 : usayama) // account -> user
 * @version 0.4 (2021.05.20 : usayama) // terms 追加
 * @version 0.5 (2021.06.12 : usayama) // 項目 追加
 * @version 0.6 (2021.06.12 : usayama) // cartForm, tools 追加
 * @version 0.7 (2021.06.16 : usayama) // payment 追加
 * @version 0.8 (2021.06.21 : usayama) // point, orderItem 追加
 * @version 0.9 (2021.06.28 : usayama) // support 追加
 * @version 0.10 (2021.06.29 : usayama) // brand 追加
 */
export default function* rootSaga(getState) {
  yield all([
    cart(),
    user(),
    auth(),
    modal(),
    terms(),
    shop(),
    order(),
    item(),
    information(),
    cartForm(),
    tools(),
    payment(),
    point(),
    order_item(),
    support(),
    brand(),
  ]) 
}

/**
 * import
 *
 * @since 0.1 (latest update : 2020.11.04)
 */
import { all } from 'redux-saga/effects';
// conf, action
// import { API_ROUTE } from '../../config/router.constants';
// import actions from './actions';
// components
// import { apiGetRequestHeader, apiPostRequestHeader } from '../../lib/helpers';
// const


export default function* rootSaga() {
  yield all([

  ]);
}

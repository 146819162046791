/**
 * action
 *
 * @version 0.1 (2021.06.12 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // id から shop_url, item_url に変更
 */
const actions = {
  // getItems
  INIT_GET_ITEMS: 'INIT_GET_ITEMS',
  GET_ITEMS: 'GET_ITEMS',
  GET_ITEMS_RESULT: 'GET_ITEMS_RESULT',
  initGetItems: () => ({ 
    type: actions.INIT_GET_ITEMS,
  }),
  getItems: () => ({ 
    type: actions.GET_ITEMS,
  }),
  // getItem
  INIT_GET_ITEM: 'INIT_GET_ITEM',
  GET_ITEM: 'GET_ITEM',
  GET_ITEM_RESULT: 'GET_ITEM_RESULT',
  initGetItem: () => ({ 
    type: actions.INIT_GET_ITEM,
  }),
  getItem: (shop_url, item_url) => ({ 
    type: actions.GET_ITEM,
    shop_url: shop_url,
    item_url: item_url,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.12 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
// const
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * GetTerms
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
const onGetTerms = async () => {
  return await axios.get(AJAX_ROUTE.getTerms).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getTerms() {
  yield takeEvery(actions.GET_TERMS, function*() {
    const getTermsResult = yield call( onGetTerms )
    yield put({
      type: actions.GET_TERMS_RESULT,
      getTermsResult: getTermsResult,
    })
  })
}

/**
 * export
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getTerms),
  ])
}

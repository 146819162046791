/**
 * action
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.14 : usayama) // addCartItem 実装, RESULT 系 コメントアウト解除
 * @version 0.3 (2021.06.15 : usayama) // addCartItem -> setCartItem に変更, removeCartItem に item 追加
 * @version 0.4 (2021.06.17 : usayama) // removeCartItems 追加
 */
const actions = {
  // getCartItems
  INIT_GET_CART_ITEMS: 'INIT_GET_CART_ITEMS',
  GET_CART_ITEMS: 'GET_CART_ITEMS',
  GET_CART_ITEMS_RESULT: 'GET_CART_ITEMS_RESULT',
  initGetCartItems: () => ({ 
    type: actions.INIT_GET_CART_ITEMS,
  }),
  getCartItems: () => ({ 
    type: actions.GET_CART_ITEMS,
  }),
  // setCartItem
  INIT_SET_CART_ITEM: 'INIT_SET_CART_ITEM',
  SET_CART_ITEM: 'SET_CART_ITEM',
  SET_CART_ITEM_RESULT: 'SET_CART_ITEM_RESULT',
  initSetCartItem: () => ({ 
    type: actions.INIT_SET_CART_ITEM,
  }),
  setCartItem: (item) => ({ 
    type: actions.SET_CART_ITEM,
    item: { item },
  }),
  // removeCartItem
  INIT_REMOVE_CART_ITEM: 'INIT_REMOVE_CART_ITEM',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  REMOVE_CART_ITEM_RESULT: 'REMOVE_CART_ITEM_RESULT',
  initRemoveCartItem: () => ({ 
    type: actions.INIT_REMOVE_CART_ITEM,
  }),
  removeCartItem: (item) => ({ 
    type: actions.REMOVE_CART_ITEM,
    item: { item },
  }),
  // removeCartItems
  REMOVE_CART_ITEMS: 'REMOVE_CART_ITEMS',
  REMOVE_CART_ITEMS_RESULT: 'REMOVE_CART_ITEMS_RESULT',
  removeCartItems: () => ({ 
    type: actions.REMOVE_CART_ITEMS,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.05.13 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
import actions from './actions';
// helper
import { getResultStatusCode, clearToken, setToken } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // GetCsrfToken 追加
 * @version 0.3 (2021.05.19 : usayama) // signUpResultStatus 追加
 * @version 0.4 (2021.06.19 : usayama) // Login, getMe, logout, verifyRegisterUrl 追加
 * @version 0.5 (2021.06.25 : usayama) // パスワードリセット関連 追加
 * @version 0.6 (2021.06.30 : usayama) // setToken(action.changePasswordResult.accessToken) コメントアウト, 
 * @version 0.7 (2021.07.01 : usayama) // logoutResultStatus追加
 */
const initState = { 
  // view
  loadSignUp: false,
  loadRegisterAccount: false,
  loadGetCsrfToken: false,
  loadLogin: false,
  loadGetMe: false,
  loadForgotPassword: false,
  loadChangePassword: false,
  // result
  result: null,
  loginResultStatus: null,
  logoutResultStatus: null,
  signUpResult: null,
  signUpResultStatus: null,
  registerAccountResult: null,
  registerAccountResultStatus: null,
  verifyRegisterUrlResult: null,
  verifyRegisterUrlResultStatus: null,
  forgotPasswordResult: null,
  forgotPasswordResultStatus: null,
  changePasswordResult: null,
  changePasswordResultStatus: null,
  verifyChangePasswordUrlResult: null,
  verifyChangePasswordUrlResultStatus: null,
  // data
  me: null,
};

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 * @version 0.2 (2021.05.19 : usayama) // signUpResultStatus に変更
 * @version 0.3 (2021.06.19 : usayama) // Login, getMe, logout 追加
 * @version 0.4 (2021.06.25 : usayama) // パスワードリセット関連 追加
 * @version 0.5 (2021.06.30 : usayama) // setToken(action.registerAccountResult.accessToken) 追加
 * @version 0.6 (2021.06.30 : usayama) // registerAccountResult, changePasswordResult に accessToken 追加
 * @version 0.7 (2021.07.01 : usayama) // init_login の clearToken() コメントアウト, logoutResultStatus追加
 */
export default function authReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- SIGN_UP --- */
    case actions.INIT_SIGN_UP: 
      return {
        ...state,
        loadSignUp: false,
        signUpResult: null,
        signUpResultStatus: null,
      };
    case actions.SIGN_UP:
      return {
        ...state,
        loadSignUp: true,
      };
    case actions.SIGN_UP_RESULT:
      return {
        ...state,
        loadSignUp: false,
        signUpResult: action.signUpResult,
        signUpResultStatus: getResultStatusCode(action.signUpResult),
      };
     /* --- REGISTER_ACCOUNT --- */
    case actions.INIT_REGISTER_ACCOUNT: 
      return {
        ...state,
        loadRegisterAccount: false,
        registerAccountResult: null,
        registerAccountResultStatus: null,
      };
    case actions.REGISTER_ACCOUNT:
      return {
        ...state,
        loadRegisterAccount: true,
      };
    case actions.REGISTER_ACCOUNT_RESULT:
      setToken(action.registerAccountResult.accessToken)
      return {
        ...state,
        loadRegisterAccount: false,
        registerAccountResult: action.registerAccountResult,
        registerAccountResultStatus: getResultStatusCode(action.registerAccountResult),
      };
     /* --- VERIFY_REGISTER_URL --- */
    case actions.INIT_VERIFY_REGISTER_URL: 
      return {
        ...state,
        loadRegisterUrl: false,
        verifyRegisterUrlResult: null,
        verifyRegisterUrlResultStatus: null,
      };
    case actions.VERIFY_REGISTER_URL:
      return {
        ...state,
        loadRegisterUrl: true,
      };
    case actions.VERIFY_REGISTER_URL_RESULT:
      return {
        ...state,
        loadRegisterUrl: false,
        verifyRegisterUrlResult: action.verifyRegisterUrlResult,
        verifyRegisterUrlResultStatus: getResultStatusCode(action.verifyRegisterUrlResult),
      };
     /* --- GET_CSRF_TOKEN --- */
    case actions.INIT_GET_CSRF_TOKEN: 
      return {
        ...state,
        loadGetCsrfToken: false,
      };
    case actions.GET_CSRF_TOKEN:
      return {
        ...state,
        loadGetCsrfToken: true,
      };
    case actions.GET_CSRF_TOKEN_RESULT:
      return {
        ...state,
        loadGetCsrfToken: false,
        result: action.getCsrfTokenResult,
      };
     /* --- LOGIN --- */
    case actions.INIT_LOGIN: 
      // clearToken()
      return {
        ...state,
        loadLogin: false,
        result: null,
        loginResult: null,
        loginResultStatus: null,
      }
    case actions.LOGIN:
      return {
        ...state,
        loadLogin: true,
      }
    case actions.LOGIN_RESULT:
      setToken(action.loginResult.accessToken)
      return {
        ...state,
        loadLogin: false,
        result: action.loginResult,
        loginResult: action.loginResult,
        loginResultStatus: getResultStatusCode(action.loginResult),
      }
     /* --- GET_ME --- */
    case actions.INIT_GET_ME: 
      return {
        ...state,
        loadGetMe: false,
        result: null,
      }
    case actions.GET_ME:
      return {
        ...state,
        loadGetMe: true,
      }
    case actions.GET_ME_RESULT:
      return {
        ...state,
        loadGetMe: false,
        result: action.getMeResult,
        getMeResultStatus: getResultStatusCode(action.getMeResult),
        me: action.getMeResult.me,
      }
     /* --- LOGOUT --- */
    case actions.LOGOUT:
      clearToken()
      return {
        ...state,
        loadLogout: true,
        logoutResultStatus: null,
        me: null,
      }
    case actions.LOGOUT_RESULT:
      return {
        ...state,
        loadLogout: false,
        result: action.logoutResult,
        logoutResultStatus: getResultStatusCode(action.logoutResult),
        me: null,
      }
     /* --- FORGOT_PASSWORD --- */
    case actions.INIT_FORGOT_PASSWORD: 
      return {
        ...state,
        loadForgotPassword: false,
        forgotPasswordResult: null,
        forgotPasswordResultStatus: null,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        loadForgotPassword: true,
      };
    case actions.FORGOT_PASSWORD_RESULT:
      return {
        ...state,
        loadForgotPassword: false,
        forgotPasswordResult: action.forgotPasswordResult,
        forgotPasswordResultStatus: getResultStatusCode(action.forgotPasswordResult),
      };
     /* --- CHANGE_PASSWORD --- */
    case actions.INIT_CHANGE_PASSWORD: 
      return {
        ...state,
        loadChangePassword: false,
        changePasswordResult: null,
        changePasswordResultStatus: null,
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        loadChangePassword: true,
      };
    case actions.CHANGE_PASSWORD_RESULT:
      // setToken(action.changePasswordResult.accessToken)
      return {
        ...state,
        loadChangePassword: false,
        changePasswordResult: action.changePasswordResult,
        changePasswordResultStatus: getResultStatusCode(action.changePasswordResult),
      };
     /* --- VERIFY_CHANGE_PASSWORD_URL --- */
    case actions.INIT_VERIFY_CHANGE_PASSWORD_URL: 
      return {
        ...state,
        loadChangePasswordUrl: false,
        verifyChangePasswordUrlResult: null,
        verifyChangePasswordUrlResultStatus: null,
      };
    case actions.VERIFY_CHANGE_PASSWORD_URL:
      return {
        ...state,
        loadChangePasswordUrl: true,
      };
    case actions.VERIFY_CHANGE_PASSWORD_URL_RESULT:
      return {
        ...state,
        loadChangePasswordUrl: false,
        verifyChangePasswordUrlResult: action.verifyChangePasswordUrlResult,
        verifyChangePasswordUrlResultStatus: getResultStatusCode(action.verifyChangePasswordUrlResult),
      };
    default:
      return state;
  }
}

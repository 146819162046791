/**
 * action
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const actions = {
  // getOrders
  INIT_GET_ORDERS: 'INIT_GET_ORDERS',
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_RESULT: 'GET_ORDERS_RESULT',
  initGetOrders: () => ({ 
    type: actions.INIT_GET_ORDERS,
  }),
  getOrders: () => ({ 
    type: actions.GET_ORDERS,
  }),
  // getOrder
  INIT_GET_ORDER: 'INIT_GET_ORDER',
  GET_ORDER: 'GET_ORDER',
  GET_ORDER_RESULT: 'GET_ORDER_RESULT',
  initGetOrder: () => ({ 
    type: actions.INIT_GET_ORDER,
  }),
  getOrder: (id) => ({ 
    type: actions.GET_ORDER,
    id: id,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // account, auth, Modal 追加
 * @version 0.3 (2021.05.18 : usayama) // Account -> User
 * @version 0.4 (2021.05.20 : usayama) // Terms 追加
 * @version 0.5 (2021.06.12 : usayama) // 項目 追加
 * @version 0.6 (2021.06.15 : usayama) // CartForm, Tools 追加
 * @version 0.7 (2021.06.16 : usayama) // Payment 追加
 * @version 0.8 (2021.06.21 : usayama) // Point, OrderItem 追加
 * @version 0.9 (2021.06.28 : usayama) // Support 追加
 * @version 0.10 (2021.06.29 : usayama) // Brand 追加
 */
import { combineReducers } from 'redux' 
import Cart from '@redux/cart/reducer'
import User from '@redux/user/reducer'
import Auth from '@redux/auth/reducer'
import Modal from '@redux/modal/reducer'
import Terms from '@redux/terms/reducer'
import Shop from '@redux/shop/reducer'
import Order from '@redux/order/reducer'
import Item from '@redux/item/reducer'
import Information from '@redux/information/reducer'
import CartForm from '@redux/cartForm/reducer'
import Tools from '@redux/tools/reducer'
import Payment from '@redux/payment/reducer'
import Point from '@redux/point/reducer'
import OrderItem from '@redux/order_item/reducer'
import Support from '@redux/support/reducer'
import Brand from '@redux/brand/reducer'

/**
 * export
 *
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.05.17 : usayama) // account, auth, Modal 追加
 * @version 0.3 (2021.05.18 : usayama) // Account -> User
 * @version 0.4 (2021.05.20 : usayama) // Terms 追加
 * @version 0.5 (2021.06.12 : usayama) // 項目 追加
 * @version 0.6 (2021.06.15 : usayama) // CartForm, Tools 追加
 * @version 0.7 (2021.06.16 : usayama) // Payment 追加
 * @version 0.8 (2021.06.21 : usayama) // Point, OrderItem 追加
 * @version 0.9 (2021.06.28 : usayama) // Support 追加
 * @version 0.10 (2021.06.29 : usayama) // Brand 追加
 */
export default combineReducers({
	Cart,
	User,
	Auth,
	Modal,
	Terms,
  Shop,
  Order,
  Item,
  Information,
  CartForm,
  Tools,
  Payment,
  Point,
  OrderItem,
  Support,
  Brand,
}) 

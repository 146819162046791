/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetShops
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const onGetShops = async () => {
  return await axios.post(AJAX_ROUTE.getShops, {}, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getShops() {
  yield takeEvery(actions.GET_SHOPS, function*() {
    const getShopsResult = yield call( onGetShops );
    yield put({
      type: actions.GET_SHOPS_RESULT,
      getShopsResult: getShopsResult,
    });
  });
}

/**
 * GetShop
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const onGetShop = async (id) => {
  return await axios.get(AJAX_ROUTE.getShop + '/' + id, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getShop() {
  yield takeEvery(actions.GET_SHOP, function*({id}) {
    const getShopResult = yield call( onGetShop, id );
    yield put({
      type: actions.GET_SHOP_RESULT,
      getShopResult: getShopResult,
    });
  });
}

/**
 * GetShopEmailTemplates
 *
 * @version 0.1 (2021.06.07 : usayama)
 */
const onGetShopEmailTemplates = async (shops_id) => {
  return await axios.get(AJAX_ROUTE.getShopEmailTemplates + '/' + shops_id, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getShopEmailTemplates() {
  yield takeEvery(actions.GET_SHOP_EMAIL_TEMPLATES, function*({shops_id}) {
    const getShopEmailTemplatesResult = yield call( onGetShopEmailTemplates, shops_id );
    yield put({
      type: actions.GET_SHOP_EMAIL_TEMPLATES_RESULT,
      getShopEmailTemplatesResult: getShopEmailTemplatesResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 * @version 0.2 (2021.06.07 : usayama) // shop_email_template 追加
 */
export default function* rootSaga() {
  yield all([
    fork(getShops),
    fork(getShop),
    fork(getShopEmailTemplates),
  ]);
}

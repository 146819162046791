/**
 * action
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const actions = {
  // getInformations
  INIT_GET_INFORMATIONS: 'INIT_GET_INFORMATIONS',
  GET_INFORMATIONS: 'GET_INFORMATIONS',
  GET_INFORMATIONS_RESULT: 'GET_INFORMATIONS_RESULT',
  initGetInformations: () => ({ 
    type: actions.INIT_GET_INFORMATIONS,
  }),
  getInformations: () => ({ 
    type: actions.GET_INFORMATIONS,
  }),
  // getInformation
  INIT_GET_INFORMATION: 'INIT_GET_INFORMATION',
  GET_INFORMATION: 'GET_INFORMATION',
  GET_INFORMATION_RESULT: 'GET_INFORMATION_RESULT',
  initGetInformation: () => ({ 
    type: actions.INIT_GET_INFORMATION,
  }),
  getInformation: (id) => ({ 
    type: actions.GET_INFORMATION,
    id: id,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama))
 */
export default actions;

/**
 * import
 *
 * @version 0.1 (2020.07.17 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
// conf, action
import { AJAX_ROUTE } from '@config/routers';
import actions from './actions';
// components
// consts

/**
 * zip コードから住所取得
 *
 * @version 1.1 (2020.07.17 : usayama)
 */
const onGetAddress = async (values) => {
  const url = AJAX_ROUTE.getAddress + values.zip_code;
  return await fetch(url).then(res => res.json()).then(res => res).catch(error => error);
}
export function* getAddress() {
  yield takeEvery(actions.GET_ADDRESS, function*({payload}) {
    const getAddressResult = yield call( onGetAddress, payload.values );
    yield put({
      type: actions.GET_ADDRESS_RESULT,
      getAddressResult: getAddressResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2020.07.17 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getAddress),
  ]);
}

/**
 * import
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
// import { ajaxGetRequestHeader, ajaxPostRequestHeader, ajaxCrosPostRequestHeader } from '@lib/helpers'
// import { showAlertMessage } from '@lib/appHelpers'
// const

/**
 * Contact
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
const onContact = async (values) => {
  return await axios.post(AJAX_ROUTE.contact, values).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* contact() {
  yield takeEvery(actions.CONTACT, function*({payload}) {
    const contactResult = yield call( onContact, payload.values )
    yield put({
      type: actions.CONTACT_RESULT,
      contactResult: contactResult,
    })
  })
}

/**
 * export
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(contact),
  ])
}

/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
import actions from './actions';
// helper
import { getResultStatusCode } from '@lib/helpers';

/**
 * initState
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const initState = { 
  // view
  loadGetOrderItems: false,
  loadGetOrderItem: false,
  // result
  result: null,
  getOrderItemsResult: null,
  getOrderItemResult: null,
  getOrderItemsResultStatus: null,
  getOrderItemResultStatus: null,
  // data
  order_items: [],
  order_item: {},
};

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
export default function orderItemReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_ORDER_ITEMS --- */
    case actions.INIT_GET_ORDER_ITEMS: 
      return {
        ...state,
        loadGetOrderItems: false,
        result: null,
        getOrderItemsResult: null,
        getOrderItemsResultStatus: null,
        order_items: [],
      };
    case actions.GET_ORDER_ITEMS:
      return {
        ...state,
        loadGetOrderItems: true,
      };
    case actions.GET_ORDER_ITEMS_RESULT:
      return {
        ...state,
        loadGetOrderItems: false,
        result: action.getOrderItemsResult,
        getOrderItemsResult: action.getOrderItemsResult,
        loginResultStatus: getResultStatusCode(action.getOrderItemsResult),
        order_items: action.getOrderItemsResult.items,
      };
     /* --- GET_ORDER_ITEM --- */
    case actions.INIT_GET_ORDER_ITEM: 
      return {
        ...state,
        loadGetOrderItem: false,
        result: null,
        getOrderItemResult: null,
        getOrderItemResultStatus: null,
      }
    case actions.GET_ORDER_ITEM:
      return {
        ...state,
        loadGetOrderItem: true,
      }
    case actions.GET_ORDER_ITEM_RESULT:
      return {
        ...state,
        loadGetOrderItem: false,
        result: action.getOrderItemResult,
        getOrderItemResult: action.getOrderItemResult,
        getOrderItemResultStatus: getResultStatusCode(action.getOrderItemResult),
        order_item: action.getOrderItemResult.item,
      }
    default:
      return state;
  }
}

/**
 * import
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetOrders
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const onGetOrders = async () => {
  return await axios.post(AJAX_ROUTE.getOrders, {}, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getOrders() {
  yield takeEvery(actions.GET_ORDERS, function*() {
    const getOrdersResult = yield call( onGetOrders );
    yield put({
      type: actions.GET_ORDERS_RESULT,
      getOrdersResult: getOrdersResult,
    });
  });
}

/**
 * GetOrder
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
const onGetOrder = async (id) => {
  return await axios.get(AJAX_ROUTE.getOrder + '/' + id, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getOrder() {
  yield takeEvery(actions.GET_ORDER, function*({id}) {
    const getOrderResult = yield call( onGetOrder, id );
    yield put({
      type: actions.GET_ORDER_RESULT,
      getOrderResult: getOrderResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.14 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getOrders),
    fork(getOrder),
  ]);
}

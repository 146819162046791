/**
 * import
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
import actions from './actions';
// helper
import { getResultStatusCode } from '@lib/helpers';

/**
 * initState
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
const initState = { 
  // view
  loadGetTerms: false,
  // result
  getTermsResult: null,
  getTermsResultStatus: null,
  // data
  terms: null,
};

/**
 * export
 *
 * @version 0.1 (2021.05.20 : usayama)
 * @version 0.2 (2021.06.23 : usayama) // status 箇所変更
 */
export default function termsReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_TERMS --- */
    case actions.INIT_GET_TERMS: 
      return {
        ...state,
        loadGetTerms: false,
        terms: null,
      };
    case actions.GET_TERMS:
      return {
        ...state,
        loadGetTerms: true,
      };
    case actions.GET_TERMS_RESULT:
      return {
        ...state,
        loadGetTerms: false,
        getTermsResult: action.getTermsResult,
        getTermsResultStatus: getResultStatusCode(action.getTermsResult),
        terms: action.getTermsResult.item,
      };
    default:
      return state;
  }
}

/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetOrderItems
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const onGetOrderItems = async () => {
  return await axios.get(AJAX_ROUTE.getOrderItems, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getOrderItems() {
  yield takeEvery(actions.GET_ORDER_ITEMS, function*() {
    const getOrderItemsResult = yield call( onGetOrderItems );
    yield put({
      type: actions.GET_ORDER_ITEMS_RESULT,
      getOrderItemsResult: getOrderItemsResult,
    });
  });
}

/**
 * GetOrderItem
 *
 * @version 0.1 (2021.05.28 : usayama)
 */
const onGetOrderItem = async (id) => {
  return await axios.get(AJAX_ROUTE.getOrderItem + '/' + id, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getOrderItem() {
  yield takeEvery(actions.GET_ORDER_ITEM, function*({id}) {
    const getOrderItemResult = yield call( onGetOrderItem, id );
    yield put({
      type: actions.GET_ORDER_ITEM_RESULT,
      getOrderItemResult: getOrderItemResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama)
 * @version 0.2 (2021.05.28 : usayama) // payment 処理追加
 */
export default function* rootSaga() {
  yield all([
    fork(getOrderItems),
    fork(getOrderItem),
  ]);
}

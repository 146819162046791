/**
 * import
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
import actions from './actions'
// helper
import { getResultStatusCode } from '@lib/helpers'

/**
 * initState
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
const initState = { 
  // view
  loadGetItems: false,
  loadGetItem: false,
  // result
  result: null,
  getItemsResult: null,
  getItemResult: null,
  getItemsResultStatus: null,
  getItemResultStatus: null,
  // data
  items: [],
  item: {},
}

/**
 * export
 *
 * @version 0.1 (2021.06.12 : usayama)
 */
export default function itemReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_ITEMS --- */
    case actions.INIT_GET_ITEMS: 
      return {
        ...state,
        loadGetItems: false,
        result: null,
        getItemsResult: null,
        getItemsResultStatus: null,
        items: [],
        item: {},
      }
    case actions.GET_ITEMS:
      return {
        ...state,
        loadGetItems: true,
      }
    case actions.GET_ITEMS_RESULT:
      // item
      let items = action.getItemsResult.items
      // profile_fields
      items.forEach((item, i) => {
        const profile_fields = item && item.profile_fields && JSON.parse(item.profile_fields)
        items[i].table_keys = profile_fields && JSON.parse(profile_fields.table_keys)
        items[i].table_values = profile_fields && JSON.parse(profile_fields.table_values)
        items[i].item_images = profile_fields.item_images ? profile_fields.item_images : []
      })
      return {
        ...state,
        loadGetItems: false,
        result: action.getItemsResult,
        getItemsResult: action.getItemsResult,
        getItemsResultStatus: getResultStatusCode(action.getItemsResult),
        items: items,
      }
     /* --- GET_ITEM --- */
    case actions.INIT_GET_ITEM: 
      return {
        ...state,
        loadGetItem: false,
        result: null,
        getItemResult: null,
        getItemResultStatus: null,
        item: {},
      }
    case actions.GET_ITEM:
      return {
        ...state,
        loadGetItem: true,
      }
    case actions.GET_ITEM_RESULT:
      // init
      let item = action.getItemResult.item
      let order_num_options = {}
      // profile_fields
      const profile_fields = item && item.profile_fields && JSON.parse(item.profile_fields)
      item.table_keys = profile_fields && JSON.parse(profile_fields.table_keys)
      item.table_values = profile_fields && JSON.parse(profile_fields.table_values)
      item.item_images = profile_fields.item_images ? profile_fields.item_images : []
      // order_num_options
      for (let i = 1; i <= item.max_length; i++) {
        order_num_options[i] = i
      }
      item.order_num_options = order_num_options
      return {
        ...state,
        loadGetItem: false,
        result: action.getItemResult,
        getItemResult: action.getItemResult,
        getItemResultStatus: getResultStatusCode(action.getItemResult),
        item: item,
      }
    default:
      return state
  }
}

/**
 * action
 *
 * @version 0.1 (2021.05.20 : usayama)
 */
const actions = {
  // getTerms
  INIT_GET_TERMS: 'INIT_GET_TERMS',
  GET_TERMS: 'GET_TERMS',
  GET_TERMS_RESULT: 'GET_TERMS_RESULT',
  initGetTerms: () => ({ 
    type: actions.INIT_GET_TERMS,
  }),
  getTerms: () => ({ 
    type: actions.GET_TERMS,
  }),
};

/**
 * export
 *
 * @version 0.1 (2021.05.20 : usayama))
 */
export default actions;

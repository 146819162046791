/**
 * import
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
import actions from './actions';
// helper
import { getResultStatusCode } from '@lib/helpers';

/**
 * initState
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
const initState = { 
  // view
  loadGetPoints: false,
  loadGetPoint: false,
  // result
  result: null,
  getPointsResult: null,
  getPointResult: null,
  getPointsResultStatus: null,
  getPointResultStatus: null,
  // data
  points: [],
  point: {},
};

/**
 * export
 *
 * @version 0.1 (2021.06.21 : usayama)
 */
export default function pointReducer(state = initState, action) {
  // init
  // action
  switch (action.type) {
     /* --- GET_POINTS --- */
    case actions.INIT_GET_POINTS: 
      return {
        ...state,
        loadGetPoints: false,
        result: null,
        getPointsResult: null,
        getPointsResultStatus: null,
        points: [],
      };
    case actions.GET_POINTS:
      return {
        ...state,
        loadGetPoints: true,
      };
    case actions.GET_POINTS_RESULT:
      return {
        ...state,
        loadGetPoints: false,
        result: action.getPointsResult,
        getPointsResult: action.getPointsResult,
        loginResultStatus: getResultStatusCode(action.getPointsResult),
        points: action.getPointsResult.items,
      };
     /* --- GET_POINT --- */
    case actions.INIT_GET_POINT: 
      return {
        ...state,
        loadGetPoint: false,
        result: null,
        getPointResult: null,
        getPointResultStatus: null,
      }
    case actions.GET_POINT:
      return {
        ...state,
        loadGetPoint: true,
      }
    case actions.GET_POINT_RESULT:
      return {
        ...state,
        loadGetPoint: false,
        result: action.getPointResult,
        getPointResult: action.getPointResult,
        getPointResultStatus: getResultStatusCode(action.getPointResult),
        point: action.getPointResult.item,
      }
    default:
      return state;
  }
}

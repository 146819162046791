/**
 * import
 *
 * @version 0.1 (2021.01.15 : usayama)
 */
import actions from './actions';

/**
 * initState
 *
 * @version 0.1 (2021.01.15 : usayama)
 * @version 0.2 (2021.05.20 : usayama) // data 削除、変数リネーム
 * @version 0.3 (2021.06.14 : usayama) // 変数リネーム
 */
const initState = { 
  // view
  // result
  // data
  isModalOpen: false,
};

/**
 * export
 *
 * @version 0.1 (2021.01.15 : usayama)
 * @version 0.2 (2021.05.18 : usayama) // data 追加
 * @version 0.3 (2021.05.20 : usayama) // data 削除、変数リネーム
 * @version 0.3 (2021.06.14 : usayama) // 変数リネーム
 */
export default function modalReducer(state = initState, action) {
  switch (action.type) {
    /* --- MODAL --- */
    case actions.INIT_MODAL: 
      return {
        ...state,
        isModalOpen: false,
      };
    case actions.OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
}

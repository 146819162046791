/**
 * action
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
const actions = {
  // contact
  INIT_CONTACT: 'INIT_CONTACT',
  CONTACT: 'CONTACT',
  CONTACT_RESULT: 'CONTACT_RESULT',
  initContact: () => ({ 
    type: actions.INIT_CONTACT,
  }),
  contact: (values) => ({ 
    type: actions.CONTACT,
    payload: { values },
  }),
}

/**
 * export
 *
 * @version 0.1 (2021.06.28 : usayama))
 */
export default actions

/**
 * import
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
import actions from './actions';
// helper

/**
 * initState
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
const initState = { 
  // view
  loadGetMe: false,
  // result
  // getMeResult: null,
  // data
  me: {},
  myEcPoint: 1000,
  myAppPoint: 4000,
};

/**
 * export
 *
 * @version 0.1 (2021.05.17 : usayama)
 */
export default function accountReducer(state = initState, action) {
  // init
  // let me = state.me
  // action
  switch (action.type) {
     /* --- GET_ME --- */
    case actions.INIT_GET_ME: 
      return {
        ...state,
        loadGetMe: false,
        me: {},
      };
    case actions.GET_ME:
      return {
        ...state,
        loadGetMe: true,
      };
    case actions.GET_ME_RESULT:
      return {
        ...state,
        loadGetMe: false,
        // me: action.getMeResult.me ? action.getMeResult.me : null,
      };
    default:
      return state;
  }
}

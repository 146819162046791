/**
 * import
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
// import fetch from 'node-fetch'
import axios from 'axios'
// conf, action
import actions from './actions'
import { AJAX_ROUTE } from '@config/routers'
// components
// helper
import { getBearer } from '@lib/helpers'
// const

/**
 * GetBrands
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
const onGetBrands = async () => {
  return await axios.get(AJAX_ROUTE.getBrands, getBearer()).then(res => res.data).catch(error => error.response && error.response.data)
}
export function* getBrands() {
  yield takeEvery(actions.GET_BRANDS, function*() {
    const getBrandsResult = yield call( onGetBrands );
    yield put({
      type: actions.GET_BRANDS_RESULT,
      getBrandsResult: getBrandsResult,
    });
  });
}

/**
 * export
 *
 * @version 0.1 (2021.06.29 : usayama)
 */
export default function* rootSaga() {
  yield all([
    fork(getBrands),
  ]);
}
